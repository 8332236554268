

























































































































































































import { Component, Vue } from 'vue-property-decorator';
import { AllianceServicesRepository } from '@/repositories/AllianceServicesRepository';
import { AllianceServiceItem } from '@/models/AllianceServiceItem';

@Component({
  components: {}
})
export default class P0107 extends Vue {
  private linkedServices: AllianceServiceItem[] = [];
  private unlinkedServices: AllianceServiceItem[] = [];

  created() {
    this.onLoad();
  }

  onLoad() {
    this.fetchLinkedStatus();
  }

  async fetchLinkedStatus() {
    const {
      linkedServices,
      unlinkedServices
    } = await this.allianceServicesRepository.getLinkedAndUnlinkedServices(
      true
    );

    this.linkedServices = linkedServices;
    this.unlinkedServices = unlinkedServices;
  }

  get allianceServicesRepository() {
    return new AllianceServicesRepository();
  }
}
